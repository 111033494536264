<template>
  <div class="detaila">
    <div class="back">
      <img src="@/assets/views/jumpdetails/background2.png" alt="">
      <div class="back1">一次性小微企业创业补贴</div>
      <div class="back2">可享2万元补贴</div>
    </div>
    <div class="center1">
      <img src="@/assets/views/jumpdetails/xz1.png" alt="">
      <div class="center1title">适用范围：</div>
    </div>
    <ul class="specific">
      高校毕业生等法定劳动年龄内各类人员创办的小微企业。
    </ul>
    <div class="heng"></div>
    <div class="center2">
      <img src="@/assets/views/jumpdetails/xz2.png" alt="">
      <div class="center1title">主要内容：</div>
    </div>
    <div class="specific1">小微企业取得营业执照等有效资质，注册登记满1年，申领补贴时法定代表人在该企业缴纳城镇职工社会保险的，可申领1.2万元一次性小微企业创业补贴，除创业者本人外至少1名职工在该企业就业的，补贴标准提高到2万元。</div>
  </div>
</template>

<script>
import "@/sass/views/Jumpdetails/detaila.scss";
export default {

}
</script>

<style>

</style>