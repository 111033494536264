<!--
 * @Description: 公积金问问
 * @Autor: bjp
 -->
<template>
  <div class="accumulationList" v-loading.fullscreen="loadingFullscreen">
    <div :class="isShowMask?'mask':''"></div>
    <v-refresh :on-refresh="onRefresh" :on-infinite-load="onInfiniteLoad">
      <div class="banner">
        <img src="@/assets/views/question/accumulation.png" alt="">
      </div>
      <div class="accumulationFund">
        <my-policy :refresh="false" ifShow="1" ref="policyList" type="2" ifLabel="2"></my-policy>
      </div>
    </v-refresh>
  </div>
</template>
<script>
import "@/sass/views/question/accumulationFund.scss"
import my_policy from "../common/PolicyList"
import refresh from "@/components/plug-in/pulldown";
export default {
  components:{
    'my-policy':my_policy,
    'v-refresh':refresh
  },
  data() {
		return {
      loadingFullscreen: '',
      isShowMask:false,
		}
  },
  methods:{
    //下拉刷新
    onRefresh(done) {
      this.$refs.policyList.showData.pageNum = 1
      this.$refs.policyList.showData.list = []
      this.$refs.policyList.getList().then(() =>{
        done(); // call done
      })
    },
    // 上拉加载
    onInfiniteLoad(done) {
      this.$refs.policyList.showData.pageNum ++
      this.$refs.policyList.getList().then(res => {
        done(res);
      });
    }
  }
}
</script>