<template>
  <div class="detaila">
    <div class="back">
      <img src="@/assets/views/jumpdetails/background2.png" alt="">
      <div class="back1">大学生创业培育“海鸥行动” </div>
      <div class="back2">给予服务机构创业指导补贴</div>
    </div>
    <div class="center1">
      <img src="@/assets/views/jumpdetails/xz1.png" alt="">
      <div class="center1title">适用范围：</div>
    </div>
    <ul class="specific">
      有创业意愿和创业培养需求的在青高校在校大学生及毕业5年内高校毕业生。
    </ul>
    <div class="heng"></div>
    <div class="center2">
      <img src="@/assets/views/jumpdetails/xz2.png" alt="">
      <div class="center1title">主要内容：</div>
    </div>
    <div class="specific1">开展创业集训、举办创业大赛、提供创业服务，通过创业培育+项目选拔+创业提升三段式大学生创业培育模式，促进和帮扶大学生创业。对优秀创业项目在我市落地的，由市财政给予服务机构创业指导补贴。

</div>
  </div>
</template>

<script>
import "@/sass/views/Jumpdetails/detaila.scss";
export default {

}
</script>

<style>

</style>