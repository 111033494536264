<template>
  <div class="detaila">
    <div class="back">
      <img src="@/assets/views/jumpdetails/background.png" alt="">
      <div class="back1">在青高校在校大学生就业能力提升补贴</div>
      <div class="back2">可获1000元-2000元补贴标准</div>
    </div>
    <div class="center1">
      <img src="@/assets/views/jumpdetails/xz1.png" alt="">
      <div class="center1title">适用范围：</div>
    </div>
    <ul class="specific">
      在青高校统招全日制非毕业年度专科及以上学历，且在读期间通过青岛市职业技能鉴定中心取得相应职业资格证书或职业技能等级证书的本市生源在校大学生。
    </ul>
    <div class="heng"></div>
    <div class="center2">
      <img src="@/assets/views/jumpdetails/xz2.png" alt="">
      <div class="center1title">主要内容：</div>
    </div>
    <div class="specific1">符合条件的在校大学生参加青岛市职业技能鉴定中心统一组织的在校大学生职业（工种）补贴目录内职业工种的技能鉴定，并取得初级（五级）、中级（四级）和高级（三级）职业资格证书或职业技能等级证书的，可享受大学生就业能力提升补贴，补贴标准分别为初级（五级）1000元、中级（四级）1500元和高级（三级）2000元。</div>
  </div>
</template>

<script>
import "@/sass/views/Jumpdetails/detaila.scss";
export default {

}
</script>

<style>

</style>