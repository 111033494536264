<template>
  <div class="detaila">
    <div class="back">
      <img src="@/assets/views/jumpdetails/background.png" alt="">
      <div class="back1">“三支一扶”计划</div>
      <div class="back2">适用农村基层的高校毕业生</div>
    </div>
    <div class="center1">
      <img src="@/assets/views/jumpdetails/xz1.png" alt="">
      <div class="center1title">适用范围：</div>
    </div>
    <ul class="specific">
      愿意到农村基层从事支农、支教、支医和扶贫工作，符合条件的高校毕业生。
    </ul>
    <div class="heng"></div>
    <div class="center2">
      <img src="@/assets/views/jumpdetails/xz2.png" alt="">
      <div class="center1title">主要内容：</div>
    </div>
    <ul class="specific">
      <li>
        补贴待遇：“三支一扶”人员服务期间，参照镇（街道）事业单位从高校毕业生中新聘用工作人员试用期满后工资收入水平标准，确定工作生活补贴。各项社会保险和住房公积金缴纳基数按照镇（街道）事业单位从高校毕业生中新聘用同类工作人员试用期满后缴纳水平确定。
      </li>
      <li>
        期满政策：2017年起，新招募的“三支一扶”人员服务期为2年。服务期满考核合格的，采取考核考察的方式公开招聘为镇事业单位工作人员。“三支一扶”人员在基层服务年限计算为工龄，其参加工作时间按其到基层报到之日起算。
      </li>
    </ul>
  </div>
</template>

<script>
import "@/sass/views/Jumpdetails/detaila.scss";
export default {

}
</script>

<style>

</style>