<template>
  <div class="detaila">
    <div class="back">
      <img src="@/assets/views/jumpdetails/background2.png" alt="">
      <div class="back1">创业担保贷款</div>
      <div class="back2">最高可享45万元贷款</div>
    </div>
    <div class="center1">
      <img src="@/assets/views/jumpdetails/xz1.png" alt="">
      <div class="center1title">适用范围：</div>
    </div>
    <ul class="specific">
      在青岛行政区域内自主创业的大中专院校、高级技工学校、技师学院毕业5年内毕业生、毕业年度在校生以及普通高校休学创业大学生。
    </ul>
    <div class="heng"></div>
    <div class="center2">
      <img src="@/assets/views/jumpdetails/xz2.png" alt="">
      <div class="center1title">主要内容：</div>
    </div>
    <div class="specific1">创业者取得营业执照等有效资质，在创业实体办理就业手续，按规定缴纳社会保险的，可申请最长3年期限的创业担保贷款，并根据带动就业情况给予最长3年的全额贴息。其中，从事个体经营的，可申请最高15万元的创业担保贷款；创办企业、民办非企业单位、社会团体、事务所等创业实体的，可申请最高45万元的创业担保贷款。</div>
  </div>
</template>

<script>
import "@/sass/views/Jumpdetails/detaila.scss";
export default {

}
</script>

<style>

</style>