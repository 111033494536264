<template>
  <div class="detaila">
    <div class="back">
      <img src="@/assets/views/jumpdetails/background.png" alt="">
      <div class="back1">青年就业见习补贴</div>
      <div class="back2">按照最低工资标的50%给予见习基地补贴</div>
    </div>
    <div class="center1">
      <img src="@/assets/views/jumpdetails/xz1.png" alt="">
      <div class="center1title">适用范围：</div>
    </div>
    <ul class="specific">
      离校2年内未就业高校毕业生和16-24周岁失业青年。
    </ul>
    <div class="heng"></div>
    <div class="center2">
      <img src="@/assets/views/jumpdetails/xz2.png" alt="">
      <div class="center1title">主要内容：</div>
    </div>
    <div class="specific1">见习期3-12个月。见习期间，见习基地为见习人员支付不低于当地最低工资标准的见习期生活补贴，为见习人员购买意外伤害保险。其中，离校2年内未就业高校毕业生和16-24周岁失业青年参加见习的，按照最低工资标的50%给予见习基地补贴。见习期间为参加见习人员购买意外伤害保险，补贴标准为每人每月15元。</div>
  </div>
</template>

<script>
import "@/sass/views/Jumpdetails/detaila.scss";
export default {

}
</script>

<style>

</style>