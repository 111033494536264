<template>
  <div class="detaila">
    <div class="back">
      <img src="@/assets/views/jumpdetails/background.png" alt="">
      <div class="back1">大学生基层公共管理和社会服务岗位</div>
      <div class="back2">平均工资标准的80%给予工作补贴</div>
    </div>
    <div class="center1">
      <img src="@/assets/views/jumpdetails/xz1.png" alt="">
      <div class="center1title">适用范围：</div>
    </div>
    <ul class="specific">
      毕业三年内未就业的青岛生源全日制高校毕业生。
    </ul>
    <div class="heng"></div>
    <div class="center2">
      <img src="@/assets/views/jumpdetails/xz2.png" alt="">
      <div class="center1title">主要内容：</div>
    </div>
    <ul class="specific">
      大学生基层服务岗位实行劳动合同管理，由承接主体与聘用人员签订劳动合同,服务期限最长3年，试用期为3个月。
      <li>
        补贴待遇：基层服务人员应发补贴按不低于青岛市上年度在岗职工平均工资标准的80%合理确定，按年度调整。承接主体按规定办理基本养老保险、基本医疗保险、失业保险、工伤保险、生育保险。
      </li>
      <li>期满政策：服务期满3年、考核合格的,可通过社会自主择业或创业,由各级人力资源社会保障部门免费提供政策咨询、就业指导、岗位推荐等就业创业服务,离岗前可免费参加一次政府补贴的综合职业能力培训。</li>
    </ul>
  </div>
</template>

<script>
import "@/sass/views/Jumpdetails/detaila.scss";
export default {

}
</script>

<style>

</style>