<template>
  <div class="detaila">
    <div class="back">
      <img src="@/assets/views/jumpdetails/background.png" alt="">
      <div class="back1">高校毕业生小微企业就业补贴</div>
      <div class="back2">每人每月400元，最长可领36个月</div>
    </div>
    <div class="center1">
      <img src="@/assets/views/jumpdetails/xz1.png" alt="">
      <div class="center1title">适用范围：</div>
    </div>
    <ul class="specific">
      2018年及以后毕业的派遣期内普通高校统招全日制本科及以上学历毕业生。
    </ul>
    <div class="heng"></div>
    <div class="center2">
      <img src="@/assets/views/jumpdetails/xz2.png" alt="">
      <div class="center1title">主要内容：</div>
    </div>
    <div class="specific1">毕业生在我市小微企业就业，企业按规定办理就业登记、缴纳城镇职工社会保险的，按每人每月400元的补贴标准，为毕业生发放不超过36个月的高校毕业生小微企业就业补贴。</div>
  </div>
</template>

<script>
import "@/sass/views/Jumpdetails/detaila.scss";
export default {

}
</script>

<style>

</style>