<template>
    <div class="agreement">
        <!-- <div class="title">注册协议</div> -->
        <div class="summary">在您注册成为档管家用户的过程中，您需要完成我们的注册流程并通过点击同意的形式在线签署以下协议，请您仔细阅读、充分理解协议中条款的全部内容。如果您对本服务条款中条款有疑问的，您可以与“档管家”顾问联系咨询，由其向您解释条款内容。如果您不同意本服务条款的任意内容，或者无法准确理解“档管家”对条款的解释，请不要进行后续操作。</div>
        <section class="rule">
            <h3>《档管家用户服务协议》</h3>
            <p class="s1-title">1、总则</p>
            <p class="s1-title">2、接受条款</p>
            <p class="s1-title">3、服务内容</p>
            <p class="s1-title">4、用户的权利与义务</p>
            <p class="s1-title">5、档管家的权利与义务</p>
            <p class="s1-title">6、生效与终止</p>
            <p class="s1-title">7、违约责任</p>
            <p class="s1-title">8、法律管辖和适用</p>
            <p class="s1-title">9、其他</p>
        </section>
        
        <section class="rule">
            <h3>《隐私政策》</h3>
            <p class="s1-title">1、社保、公积金及个税代理服务</p>
            <p class="s1-title">2、个人信息用途</p>
            <p class="s1-title">3、征得授权同意的例外</p>
            <p class="s1-title">4、个人信息安全性</p>
            <p class="s1-title">5、我们如何使用cookies等技术</p>
            <p class="s1-title">6、终止服务</p>
            <p class="s1-title">7、协议修订</p>
            <p class="s1-title">8、争议解决</p>
        </section>
        <div class="summary">在您注册成为档管家用户的过程中，您需要完成我们的注册流程并通过点击同意的形式在线签署以下协议，请您仔细阅读、充分理解协议中条款的全部内容。如果您对本服务条款中条款有疑问的，您可以与“档管家”顾问联系咨询，由其向您解释条款内容。如果您不同意本服务条款的任意内容，或者无法准确理解“档管家”对条款的解释，请不要进行后续操作。</div>
        <section class="rule">
            <h3>生效与终止</h3>
            <p class="s1-title">1、与您约定免除或限制责任的条款；</p>
            <p class="s1-title">2、与您约定法律适用和管辖的条款；</p>
        </section>
        <div class="summary">【请您注意】如果您不同意上述协议或其中任何条款约定，请您停止注册。您停止注册后将仅能浏览我们的信息但无法享受我们的产品或服务。如您按照注册流程提示注册信息，阅读并点击上述协议且完成全部注册流程后，即表示您已充分阅读、理解并接受协议的全部内容，并标明您也同意档管家可以依据以下隐私政策内容来处理您的个人信息。如您对以上协议内容有任何疑问，您可随时通过隐私政策下的联系方式联系我们。</div>
        <div class="summary">点击同意即表示您已阅读同意《档管家用户服务协议》与《隐私政策》，并同意我们将您的订单信息共享给为完成此订单所必须的的第三方合作方。</div>
        <div class="summary">如您对协议有任何疑问，可向我们顾问咨询。</div>
        <div class="footer">【档管家】</div>
    </div>
</template>

<script>
import '@/sass/views/login/agreement.scss'
export default {
}
</script>
