<template>
  <div class="mine-index" v-loading.fullscreen="loadingFullscreen">
    <div class="header-con" :style="{paddingTop: $statusBarHeight + 'px' }">
      <div
        v-if="userInfo.id != ''"
        class="message-con"
        @click="$router.forward({path:'/mine/message'})"
         :style="{paddingTop: $statusBarHeight + 'px' }"
      >
        <div v-if="messageCount>0" class="has-message"></div>
        <img src="@/assets/views/mine/message-icon.png" alt class="message-btn" />
      </div>
      <div class="mine-info">
        <div class="face">
          <img class="face-img" v-if="userInfo.id == ''" src="@/assets/face.png" alt />
          <template v-else>
            <img class="face-img" v-if="userInfo.headimageurl" :src="userInfo.headimageurl" alt />
            <img class="face-img" v-else src="@/assets/face.png" alt />
          </template>
        </div>
        <div
          v-if="userInfo.id == ''"
          @click="$router.forward('/login/login')"
          class="userinfo"
        >登录/注册</div>
        <div v-else class="userinfo">{{userInfo.nickname?userInfo.nickname:userInfo.tel}}</div>
      </div>
    </div>
    <div class="main-info">
      <div class="function">
        <div class="item" @click="goMyBusiness()">
          <img src="@/assets/views/mine/business-icon.png" alt class="icon" />
          <div class="title">业务</div>
        </div>
        <div class="item" @click="$router.forward('/creatorder/orderlist')">
          <img src="@/assets/views/mine/order-icon.png" alt class="icon" />
          <div class="title">订单</div>
        </div>
        <div class="item" @click="goMyUserInfo()">
          <img src="@/assets/views/mine/data-icon.png" alt class="icon" />
          <div class="title">资料</div>
        </div>
        <!-- <div class="item" @click="$router.forward('/mine/balance')">
          <img src="@/assets/views/mine/data-icon.png" alt class="icon" />
          <div class="title">余额</div>
        </div>-->
      </div>
      <div class="fun-list">
        <div class="item" @click="$router.forward('/mine/authentication')">
          <img src="@/assets/views/mine/attestation-icon.png" alt class="icon-img" />
          <span class="title">实名认证</span>
        </div>
        <div class="item" @click="$router.forward('/mine/paybackorder')">
          <img src="@/assets/views/mine/supplement-icon.png" alt class="icon-img" />
          <span class="title">补缴订单</span>
        </div>
        <div class="item" @click="$router.forward('/mine/updatetel')">
          <img src="@/assets/views/mine/phone-icon.png" alt class="icon-img" />
          <span class="title">更换手机号</span>
        </div>
        <div class="item" @click="$router.forward('/mine/service')">
          <img src="@/assets/views/mine/service-icon.png" alt class="icon-img" />
          <span class="title">专属顾问</span>
        </div>
        <div class="item" @click="goPath('/expressmail/index')">
          <img src="@/assets/views/mine/address-icon.png" alt class="icon-img" />
          <span class="title">快递管理</span>
        </div>
        <div class="item" @click="$router.forward('/mine/set')">
          <img src="@/assets/views/mine/set-icon.png" alt class="icon-img" />
          <span class="title">设置</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/sass/views/mine/index.scss";
import { mapGetters } from "vuex";
export default {
  name: "Mine",
  data() {
    return {
      loadingFullscreen: "",
      messageCount: 0
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  mounted() {
    this.getUserInfo();
    this.getMessageCount();
  },
  methods: {
    getMessageCount() {
      if (this.userInfo.id != "")
        this.$siaxios
          .get(`/pushmessage/getUnread/${this.userInfo.id}`)
          .then(res => {
            this.messageCount = res;
          });
    },
    /**
     * 获取用户信息
     */
    getUserInfo() {
      this.$siaxios.get(`/login/getappuser/${this.userInfo.id}`).then(res => {
        if (this.userInfo.device) {
          res.device = this.userInfo.device;
        }
        res.openid = this.userInfo.openid;
        res.cityid = this.userInfo.cityid;
        res.cityname = this.userInfo.cityname;
        this.$store.commit("setMyUserInfo", res);
      });
    },
    goMyBusiness() {
      if (this.userInfo.renzhengstate != 1) {
        this.$confirm({
          describe: "办理业务请先认证，是否跳转认证页面",
          callback: boolean => {
            if (boolean) this.$router.forward("/mine/authentication");
          }
        });
        return;
      }
      this.$router.forward("/mine/business");
    },
    goPath() {
      if (this.userInfo.renzhengstate != 1) {
        this.$confirm({
          title:'提示',
          describe: "使用快递管理需要先认证，是否跳转认证页面",
          callback: boolean => {
            if (boolean) this.$router.forward("/mine/authentication");
          }
        });
        return;
      }
      this.$router.forward('/expressmail/index')
    },
    goMyUserInfo() {
      if (this.userInfo.renzhengstate != 1) {
        this.$confirm({
          describe: "办理业务请先认证，是否跳转认证页面",
          callback: boolean => {
            if (boolean) this.$router.forward("/mine/authentication");
          }
        });
        return;
      }
      this.$router.forward("/mine/userdetailinfo");
    }
  }
};
</script>
