<template>
  <div class="detaila">
    <div class="back">
      <img src="@/assets/views/jumpdetails/background.png" alt="">
      <div class="back1">大学生综合职业能力培训补贴</div>
      <div class="back2">每人最高1000元</div>
    </div>
    <div class="center1">
      <img src="@/assets/views/jumpdetails/xz1.png" alt="">
      <div class="center1title">适用范围：</div>
    </div>
    <ul class="specific">
      在青高校大学生和本市户籍派遣期内离校未就业高校毕业生
    </ul>
    <div class="heng"></div>
    <div class="center2">
      <img src="@/assets/views/jumpdetails/xz2.png" alt="">
      <div class="center1title">主要内容：</div>
    </div>
    <div class="specific1">符合条件的大学生可参加一次政府补贴的综合职业能力培训，培训补贴标准每人最高1000元。</div>
  </div>
</template>

<script>
import "@/sass/views/Jumpdetails/detaila.scss";
export default {

}
</script>

<style>

</style>