<template>
    <div class="home-index" v-loading.fullscreen="loadingFullscreen">
        <div class="index-head" :style="{paddingTop: $statusBarHeight + 'px' }">
            <div class="title"><span>档管家</span>让职场人享受更多福利保障</div>
            <div class="message" @click="$router.forward({path:'/mine/message'})"><img src="@/assets/views/index/msg.png" alt=""></div>
        </div>
        <div class="index-head-copy" :style="{paddingTop: $statusBarHeight + 'px' }"></div>
        <div class="search-con">
            <div class="search-main">
                <div class="search-left"><img src="@/assets/views/index/search-left.png" alt=""></div>
                <div class="search-input" @click="$router.forward({path:'/index/search'})">
                    <!-- <input type="text"> -->
                    <div class="input-placeholds">
                        <img src="@/assets/views/index/search.png" alt="">
                        社保补缴
                    </div>
                </div>
            </div>
        </div>
        <div class="function">
            <div class="item" @click="$router.forward({path:'/gallery/graduatefw',query: { type: 0 }})">
                <div class="icon">
                    <img src="@/assets/views/index/graduate.png" alt class="icon-img" />
                </div>
                <div class="title">应届毕业生</div>
            </div>
            <div class="item" @click="RouterBool(3)">
                <div class="icon">
                    <img src="@/assets/views/index/dangan.png" alt class="icon-img" />
                </div>
                <div class="title">档案服务</div>
            </div>
            <div class="item" @click="RouterBool(1)">
                <div class="icon">
                    <img src="@/assets/views/index/sec.png" alt class="icon-img" />
                </div>
                <div class="title">社保服务</div>
            </div>
            <div class="item" @click="RouterBool(2)">
                <div class="icon">
                    <img src="@/assets/views/index/fund.png" alt class="icon-img" />
                </div>
                <div class="title">公积金</div>
            </div>
        </div>

        <div class="service-con" @click="showConsult()">
            <img src="@/assets/views/index/kefu.png" class="service-bg" alt />
        </div>
        <div class="index-type">
            <div class="type-title">档案小课堂</div>
            <div class="type-con">
                <div class="video-con">
                    <div class="video-list">
                        <div class="video-item" v-for="(item,index) in videoList" :key="index">
                            <video :src="item.url" :ref="'video'+index" @click="handleVideo(index)" width="100%" :poster="item.imgurl">
                                您的浏览器不支持 video 标签。
                            </video>
                            <div>{{item.name}}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="index-type">
            <div class="type-title">办事指南</div>
            <div class="type-con">
                <div class="guide-tab">
                    <span v-for="(item,index) in guideList" :key="index" @click="guideIndex=index"
                        :class="{on:guideIndex==index}">{{item.name}}</span>
                </div>
                <div class="guide-con">
                    <div class="guide-item" v-for="(item,index) in guideList[guideIndex].list" :key="index" @click="$router.forward(item.url)">
                        <img :src="item.img" alt="">
                        <div>{{item.name}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="index-type">
            <div class="type-title">职场工具</div>
            <div class="type-con">
                <div class="util-list">
                    <div class="util-item" v-for="(item,index) in utilList" :key="index" @click="utilGO(item.routerLink,index)">
                        <img :src="item.img" class="c-img" alt />
                        <div class="c-title">{{item.name}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 隐私协议 -->
        <div class="normal-confirm-wrapper" v-if="agreementShow">
            <div class="s-confirm-tips">
                <div class="content">
                    <div class="my-title">用户协议与隐私政策</div>
                    <div class="my-describe">
                        请您在使用(或继续使用)我们的产品服务前仔细阅读
                        <span @click="$router.forward('/login/agreement')">《用户服务协议》</span>和
                        <span @click="$router.forward('/login/privacyagreement')">《隐私协议》</span>，
                        您点击“同意”按钮，即表示您已阅读并同意以上条款。档管家将全力保障您的合法权益与信息安全，并将持续为您提供更优质的服务。
                    </div>
                </div>
                <div class="btnShow">
                    <div class="con-bottom c-blue" @click="submit">同意</div>
                    <div class="con-bottom" @click="close">暂不使用</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import "@/sass/views/index/index.scss";
import { mapGetters } from "vuex";
import "@/sass/swiper.min.css";
import Swiper from "swiper";

export default {
    name: "IndexPage",
    data() {
        return {
            agreementShow: false,
            guideIndex: 0,
            videoIndex: 0,
            isPlay: false,
            utilList: [
                { name: '工资计算器', img: require('@/assets/views/index/gz.png'), routerLink: '/calculator/incomeTax' },
                { name: '社保计算器', img: require('@/assets/views/index/sb.png'), routerLink: '/calculator/security' },
                { name: '公积金计算器', img: require('@/assets/views/index/gjj.png'), routerLink: '/calculator/fund' },
                { name: '学历查询', img: require('@/assets/views/index/xl.png'), routerLink: '/search/eduction' },
            ],
            videoList: [],
            guideList: [
                {
                    name: '档案',
                    list: [
                        { name: '档案办理', img: require('@/assets/views/index/guide/danganbanli.png'), url: '/gallery/danganbanli' },
                        { name: '档案借阅', img: require('@/assets/views/index/guide/jieyue.png'), url: '/gallery/danganbro?id=1' },
                        { name: '档案提取', img: require('@/assets/views/index/guide/tiqu.png'), url: '/gallery/dangantq' },
                        { name: '档案归还', img: require('@/assets/views/index/guide/guihuan.png'), url: '/gallery/danganreturn?id=1' },
                        { name: '报到手续', img: require('@/assets/views/index/guide/baodao.png'), url: '/expressmail/expressarchives?type=1' },
                        { name: '档案整理', img: require('@/assets/views/index/guide/zhengli.png'), url: '/gallery/prove-zhengli' },
                        { name: '档案激活', img: require('@/assets/views/index/guide/jihuo.png'), url: '/gallery/prove-active' },
                        { name: '档案证明', img: require('@/assets/views/index/guide/zhengming.png'), url: '/gallery/prove' }
                    ]
                },
                {
                    name: '证明开具',
                    list: [
                        { name: '工作证明', img: require('@/assets/views/index/guide/gzzm.png') },
                        { name: '代理证明', img: require('@/assets/views/index/guide/daili.png') },
                        { name: '考公务员同意报考证明', img: require('@/assets/views/index/guide/gongwuyuan.png') },
                        { name: '解约函', img: require('@/assets/views/index/guide/jieyuehan.png') },
                        { name: '存档证明', img: require('@/assets/views/index/guide/cundang.png') },
                        { name: '政审证明', img: require('@/assets/views/index/guide/zhengshen.png') },
                        { name: '考研同意报考证明', img: require('@/assets/views/index/guide/kaoyan.png') },
                        { name: '其他证明', img: require('@/assets/views/index/guide/other.png') }
                    ]
                }
            ],
            messageCount: 0, //未读消息条数
            param: {
                phone: "",
                content: ""
            },
            bannerList: [], //banner列表
            cttrType: 1,
            loadingFullscreen: "",
            businessType: [],
            businessDetailList: [],
            keyword: "",
            showData: {
                pageNum: 1,
                list: []
            },
            cityid: "" //当前所在城市的id
        };
    },
    directives: {
        focus: {
            inserted: function (el) {
                el.focus();
            }
        }
    },
    computed: {
        ...mapGetters({
            userInfo: "getUserInfo"
        })
    },
    mounted() {
        if (this.$isPlus && !localStorage.getItem('agreement')) {
            this.agreementShow = true;
        }
        if (!this.userInfo.cityid) {
            // this.getPosition();
            let tempUserInfo = JSON.parse(JSON.stringify(this.userInfo));
            tempUserInfo.cityid = '171';
            tempUserInfo.cityname = '青岛市';
            this.$store.commit("setMyUserInfo", tempUserInfo);
        }
        // console.log(this.userInfo.cityid);
        this.getMessageCount();
        this.getVideo();
    },
    methods: {
        close() {
            /* eslint-disable */
            uni.postMessage({
                data: {
                    message: '我要退出!',
                    type: 'outApp'
                }
            })
        },
        submit() {
            localStorage.setItem('agreement', true);
            this.agreementShow = false;
        },
        getVideo() {
            this.$siaxios
                .get(`/video/list/1`)
                .then(res => {
                    this.videoList = res.list;
                });
        },
        utilGO(link, index) {
            if (index < 3) {
                this.$router.forward(link)
            } else {
                if (this.$isPlus) {
                    /* eslint-disable */
                    uni.navigateTo({
                        url: '/pages/index/education'
                    });
                } else {
                    window.location.href = 'https://my.chsi.com.cn/archive/wap/index.jsp'
                }

            }

        },
        handleVideo(index) {
            if (this.videoIndex != index) {
                this.$refs['video' + this.videoIndex][0].pause();
                this.$refs['video' + index][0].play();
                this.$refs['video' + index][0].requestFullscreen();
                this.videoIndex = index;
                this.isPlay = true;
            } else {
                this.videoIndex = index;
                if (this.isPlay) {
                    this.$refs['video' + index][0].pause();
                    this.isPlay = false;
                } else {
                    this.$refs['video' + index][0].play();
                    this.$refs['video' + index][0].requestFullscreen()
                    this.isPlay = true;
                }

            }
        },
        /*
        路由跳转判断
        * */
        RouterBool(type) {
            if (type == 1) {
                this.$router.forward({
                    path: "/gallery/securityfw",
                    query: { cityid: this.userInfo.cityid }
                });
            } else if (type == 2) {
                this.$router.forward("/gallery/gjjfuwu");
            } else if (type == 3) {
                this.$router.forward("/gallery/daguanli");
            } else if (type == 4) {
                this.$router.forward({
                    path: "/gallery/hkguanli",
                    query: { cityid: this.userInfo.cityid }
                });
            }
        },
        /**
         * 初始banner
         */
        iniSwiper() {
            this.$nextTick(() => {
                new Swiper("#swiper1", {
                    slidesPerView: 1, //每屏显示的个数
                    spaceBetween: 30, //间隔距离
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true
                    },
                    autoplay: true
                });
            });
        },
        /**
         * 初始化业务大类滚动
         */
        /* eslint-disable */
        iniBusiness() {
            this.$nextTick(() => {
                let winWidth = document.documentElement.clientWidth;
                let navBarUl = this.$refs.navBarUl;
                let oLi = navBarUl.getElementsByClassName("item");
                navBarUl.style.width = "3000px";
                var allListWidth = 0;
                for (let i = 0; i < oLi.length; i++) {
                    allListWidth += oLi[i].offsetWidth;
                }
                navBarUl.style.width = allListWidth + 20 + "px";
                let startX = 0;
                let cuttrX = 0;
                navBarUl.style.transition = "all .5s";
                navBarUl.addEventListener("touchstart", e => {
                    //e就是事件
                    startX = e.targetTouches[0].pageX;
                    cuttrX = navBarUl.offsetLeft;
                });
                navBarUl.addEventListener("touchmove", e => {
                    let leftpull = winWidth - allListWidth;
                    if (navBarUl.style.transition.split(" ")[0] != "none") {
                        navBarUl.style.transition = "none";
                    }
                    //移动的差值
                    let tempRutn = e.targetTouches[0].pageX - startX;
                    //nav距左位置
                    let mlNum = cuttrX + tempRutn;
                    if ((mlNum >= 0 && tempRutn > 0) || leftpull > 0) {
                        navBarUl.style.marginLeft = "0";
                    } else if (tempRutn < 0 && navBarUl.offsetLeft <= leftpull) {
                        navBarUl.style.marginLeft = leftpull - 30 + "px";
                    } else {
                        navBarUl.style.marginLeft = cuttrX + tempRutn + "px";
                    }
                    navBarUl.addEventListener("touchend", e => {
                        //e就是事件
                        navBarUl.style.transition = "all .5s";
                    });
                });
                // navBar点击
                for (let i = 0; i < oLi.length; i++) {
                    oLi[i].onclick = function () {
                        let nBLeft = navBarUl.offsetLeft;
                        let nBWidth = navBarUl.offsetWidth;
                        let maxML = -nBWidth + winWidth;
                        let mlDistance = nBLeft - this.offsetLeft + this.offsetWidth / 2;
                        if (mlDistance > 0) {
                            navBarUl.style.marginLeft = 0;
                        } else if (mlDistance <= maxML) {
                            navBarUl.style.marginLeft = maxML + "px";
                        } else {
                            navBarUl.style.marginLeft = mlDistance + "px";
                        }
                    };
                }
            });
        },
        //获取地理位置
        /* eslint-disable */
        getPosition() {
            var map, geolocation;
            //加载地图，调用浏览器定位服务
            map = new AMap.Map("container", {
                resizeEnable: true
            });
            map.plugin("AMap.Geolocation", function () {
                geolocation = new AMap.Geolocation({
                    enableHighAccuracy: true, //是否使用高精度定位，默认:true
                    timeout: 1000 //超过10秒后停止定位，默认：无穷大
                });
                map.addControl(geolocation);
                geolocation.getCurrentPosition();
                AMap.event.addListener(geolocation, "complete", onComplete); //返回定位信息
                AMap.event.addListener(geolocation, "error", onError); //返回定位出错信息
            });
            let _this = this;

            //解析定位结果
            function onComplete(data) {

                _this.$axios
                    .get(
                        "https://restapi.amap.com/v3/geocode/regeo?key=4c18d7d33e5906307cfe36ed803fd7c0&location=" +
                        data.position.lng +
                        "," +
                        data.position.lat +
                        "&radius=3&extensions=all&batch=false&roadlevel=0"
                    )
                    .then(response => {
                        // this.queryParam.province= response.data.regeocode.addressComponent.province
                        if (response.data.regeocode.addressComponent.city.length != 0) {
                            //存储当前城市
                            _this.cityName = response.data.regeocode.addressComponent.city;
                            localStorage.setItem(
                                "cityname",
                                response.data.regeocode.addressComponent.city
                            );
                            _this.getCityId(_this.cityName);
                        }
                    });
            }

            //解析定位错误信息
            function onError(data) {
                console.log(data)
                _this.$alert('定位失败，自动切换至青岛市!')
                _this.getCityId('青岛市');
                // _this.$tips({
                //   type: "warning", //warning delete
                // //   describe: "定位失败，请左上角选取城市!"+data,
                //   describe: "定位失败，自动切换至青岛市!",
                //   showbtn: true
                // });
            }
        },
        getMessageCount() {
            if (this.userInfo.id != "")
                this.$siaxios
                    .get(`/pushmessage/getUnread/${this.userInfo.id}`)
                    .then(res => {
                        this.messageCount = res;
                    });
        },
        //根据城市name获取城市id
        getCityId(cityname) {
            this.$siaxios
                .get("/region/selectByCityName?cityname=" + cityname)
                .then(res => {
                    if (res.length > 0) {
                        let tempUserInfo = JSON.parse(JSON.stringify(this.userInfo));
                        tempUserInfo.cityid = res[0].id;
                        tempUserInfo.cityname = cityname;
                        this.$store.commit("setMyUserInfo", tempUserInfo);
                    }
                });
        },
        /**
         * 展示顾问
         */
        showConsult() {
            if (this.userInfo.id == "") {
                this.$router.forward("/login/login");
                return;
            }
            this.$consult({ userid: this.userInfo.id });
        },
        selectCity() {
            this.$cityselect({
                callback: (id, name) => {
                    let tempUserInfo = JSON.parse(JSON.stringify(this.userInfo));
                    tempUserInfo.cityid = id;
                    tempUserInfo.cityname = name;
                    this.$store.commit("setMyUserInfo", tempUserInfo);
                    this.$forceUpdate();
                }
            });
        },
        //根据城市name获取城市id
        vipFrom() {
            this.$siaxios
                .post("/leavemessage/saveMessage", this.param, false)
                .then(res => {
                    this.$submitSuccess();
                });
        }
    }
};
</script>
<style lang="scss">
.home-index .banner-con .swiper-container {
    .swiper-pagination-bullet {
        background: #fff;
        &-active {
            background: #fff;
        }
    }
}
.home-index .tool .calc.type1 {
    overflow: unset;
}
</style>
