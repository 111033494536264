<template>
  <div class="detaila">
    <div class="back">
      <img src="@/assets/views/jumpdetails/background.png" alt="">
      <div class="back1">在青就业高校毕业生住房补贴</div>
      <div class="back2">最高1500元/月&nbsp;最长36个月</div>
    </div>
    <div class="center1">
      <img src="@/assets/views/jumpdetails/xz1.png" alt="">
      <div class="center1title">适用范围：</div>
    </div>
    <ul class="specific">
      <li>派遣期内国内普通高校统招全日制研究生</li>
      <li>2018年及以后毕业，派遣期内国内普通高校统招全日制本科学历毕业生；</li>
      <li>2016年以后首次来青就业并取得教育部留学服务中心国（境）外学历学位认证的留学回国研究生；</li>
      <li>2018年及以后毕业且首次来青就业，并取得教育部留学服务中心国（境）外学历学位认证的留学回国本科学历毕业生。</li>
    </ul>
    <div class="heng"></div>
    <div class="center2">
      <img src="@/assets/views/jumpdetails/xz2.png" alt="">
      <div class="center1title">主要内容：</div>
    </div>
    <div class="specific1">对在青岛行政区域内就业，取得全日制研究生、本科学历且获得博士、硕士、学士学位，具有本市户籍的毕业生，分别按照本科毕业生500元/月，硕士研究生800元/月，博士研究生1200元/月的补贴标准（其中，紧缺专业硕士研究生1200元/月，紧缺专业博士研究生1500元/月），给予最长不超过36个月的住房补贴。政策如有调整，按新政策执行。</div>
  </div>
</template>

<script>
import "@/sass/views/Jumpdetails/detaila.scss";
export default {

}
</script>

<style>

</style>