<template>
  <div class="detaila">
    <div class="back">
      <img src="@/assets/views/jumpdetails/background.png" alt="">
      <div class="back1">青年人才在青创新创业一次性安家费</div>
      <div class="back2">博士研究生15万元/人、硕士研究生10万元/人</div>
    </div>
    <div class="center1">
      <img src="@/assets/views/jumpdetails/xz1.png" alt="">
      <div class="center1title">适用范围：</div>
    </div>
    <ul class="specific">
      国内普通高校统招全日制研究生、教育部留学服务中心国（境）外学历学位认证的留学回国研究生。博士研究生年龄40周岁以下，硕士研究生年龄35周岁以下（以申报时实际年龄为准）。
    </ul>
    <div class="heng"></div>
    <div class="center2">
      <img src="@/assets/views/jumpdetails/xz2.png" alt="">
      <div class="center1title">主要内容：</div>
    </div>
    <div class="specific1">2018年6月6日以后，在青岛市行政辖区内就业或创业并在我市购买首套商品住房，具有本市户籍的，按照博士研究生每人15万元、硕士研究生每人10万元标准给予一次性安家费。</div>
  </div>
</template>

<script>
import "@/sass/views/Jumpdetails/detaila.scss";
export default {

}
</script>

<style>

</style>