<template>
  <div class="order-success">
    <img src="@/assets/success.png" class="order-success" alt />
    <div class="title">提交成功</div>
    <div class="content">申请已成功提交，稍后工作人员会与你联系</div>
    <div class="success-back" @click="$router.forward('/index/index')">返回首页</div>
  </div>
</template>
<script>
import "@/sass/views/creatorder/success.scss";
export default {
  name: "CreatgeneralSuccess",
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    
  }
};
</script>
