<!--
 * @Description: 社保问问type1社保
 * @Autor: bjp
 -->
<template>
  <div class="socialsecurityList" v-loading.fullscreen="loadingFullscreen" ref="socialsecurityList">
    <div :class="isShowMask?'mask':''"></div>
    <v-refresh :on-refresh="onRefresh" :on-infinite-load="onInfiniteLoad" ref="vrefresh">
      <div class="topBar">
        <ul>
          <li
            v-for="item in showData"
            :key="item.id"
            @click="jumpTo(item.id)">
            <span>{{item.title}}</span>
          </li>
        </ul>
      </div>
      <div class="socialSecurity">
        <my-policy :refresh="false" ref="policyList" type="1" ifLabel="2"></my-policy>
      </div>
    </v-refresh>
  </div>
</template>
<script>
import "@/sass/views/question/socialSecurity.scss"
import my_policy from "../common/PolicyList"
import refresh from "@/components/plug-in/pulldown";
export default {
  components:{
    'my-policy':my_policy,
    'v-refresh':refresh
  },
  data() {
		return {
      loadingFullscreen: '',
      showData:[
        {id:1,title:'养老'},
        {id:2,title:'医疗'},
        {id:3,title:'工伤'},
        {id:4,title:'生育'},
        {id:5,title:'失业'},
        {id:6,title:'社保卡'},
      ],
      isShowMask:false
		}
  },
  methods:{
    jumpTo(id){
      let url
      switch (id) {
        case 1:
          url = '/pension/list'
          break
        case 2:
          url = '/gallery/yiliaobx'
          break
        case 3:
          url = '/gallery/gongshangfx'
          break
        case 4:
          url = '/gallery/shengyubx'
          break
        case 5:
          url = '/gallery/shiyebx'
          break
        case 6:
          url = '/gallery/shebaoka'
          break
        default:
          break;
      }
      this.$router.forward({path:url})
    },
    //下拉刷新
    onRefresh(done) {
      this.$refs.policyList.showData.pageNum = 1
      this.$refs.policyList.showData.list = []
      this.$refs.policyList.getList().then(() =>{
        done(); // call done
      })
    },
    // 上拉加载
    onInfiniteLoad(done) {
      this.$refs.policyList.showData.pageNum ++
      this.$refs.policyList.getList().then(res => {
        done(res);
      });
    }
  }
}
</script>