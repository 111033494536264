<template>
  <div class="detaila">
    <div class="back">
      <img src="@/assets/views/jumpdetails/background2.png" alt="">
      <div class="back1">小微企业创业担保贷款</div>
      <div class="back2">最高可申请300万元&nbsp;最长2年贷款</div>
    </div>
    <div class="center1">
      <img src="@/assets/views/jumpdetails/xz1.png" alt="">
      <div class="center1title">适用范围：</div>
    </div>
    <ul class="specific">
      高校毕业生等法定劳动年龄内各类人员创办的小微企业。
    </ul>
    <div class="heng"></div>
    <div class="center2">
      <img src="@/assets/views/jumpdetails/xz2.png" alt="">
      <div class="center1title">主要内容：</div>
    </div>
    <div class="specific1">小微企业取得营业执照等有效资质，申请贷款前1年内（以贷款发放日为准）新招用人员达到职工总数25%以上（100人以上的企业达到15%）的，可按规定申请最高300万元、期限最长2年的小微企业创业担保贷款，并按照同期限贷款基准利率的50%给予贷款贴息。

</div>
  </div>
</template>

<script>
import "@/sass/views/Jumpdetails/detaila.scss";
export default {

}
</script>

<style>

</style>