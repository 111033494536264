<template>
  <div class="detaila">
    <div class="back">
      <img src="@/assets/views/jumpdetails/background.png" alt="">
      <div class="back1">用人单位吸纳就业社会保险补贴和岗位补贴</div>
      <div class="back2">每人每月200元，补贴期限为3年</div>
    </div>
    <div class="center1">
      <img src="@/assets/views/jumpdetails/xz1.png" alt="">
      <div class="center1title">适用范围：</div>
    </div>
    <ul class="specific">
      青岛行政区域内招用就业困难高校毕业生的用人单位及招用毕业年度高校毕业生的小微企业。
    </ul>
    <div class="heng"></div>
    <div class="center2">
      <img src="@/assets/views/jumpdetails/xz2.png" alt="">
      <div class="center1title">主要内容：</div>
    </div>
    <div class="specific1">用人单位与招用人员签订1年及以上期限劳动合同，办理就业登记，按规定缴纳城镇职工社会保险的，可申领养老、医疗、失业、生育、工伤五项社会保险补贴和每人每月200元的岗位补贴，补贴期限为3年。</div>
  </div>
</template>

<script>
import "@/sass/views/Jumpdetails/detaila.scss";
export default {

}
</script>

<style>

</style>