<template>
  <div class="detaila">
    <div class="back">
      <img src="@/assets/views/jumpdetails/background2.png" alt="">
      <div class="back1">一次性创业岗位开发补贴</div>
      <div class="back2">每个岗位2000元的标准</div>
    </div>
    <div class="center1">
      <img src="@/assets/views/jumpdetails/xz1.png" alt="">
      <div class="center1title">适用范围：</div>
    </div>
    <ul class="specific">
      符合申领一次性创业补贴及一次性小微企业创业补贴申领条件人员创办的创业实体。
    </ul>
    <div class="heng"></div>
    <div class="center2">
      <img src="@/assets/views/jumpdetails/xz2.png" alt="">
      <div class="center1title">主要内容：</div>
    </div>
    <div class="specific1">创业实体招用登记失业人员及毕业5年内的大中专院校、高级技工学校、技师学院毕业生，与其签订1年及以上期限劳动合同，办理就业登记，申领补贴时招用人员社会保险正常缴纳的，按每个岗位2000元的标准给予一次性创业岗位开发补贴。</div>
  </div>
</template>

<script>
import "@/sass/views/Jumpdetails/detaila.scss";
export default {

}
</script>

<style>

</style>