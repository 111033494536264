<template>
  <div class="detaila">
    <div class="back">
      <img src="@/assets/views/jumpdetails/background.png" alt="">
      <div class="back1">灵活就业社会保险补贴</div>
      <div class="back2">每人每月最高500元，补贴期限为3年</div>
    </div>
    <div class="center1">
      <img src="@/assets/views/jumpdetails/xz1.png" alt="">
      <div class="center1title">适用范围：</div>
    </div>
    <ul class="specific">
      离校1年内未就业普通高校毕业生。
    </ul>
    <div class="heng"></div>
    <div class="center2">
      <img src="@/assets/views/jumpdetails/xz2.png" alt="">
      <div class="center1title">主要内容：</div>
    </div>
    <div class="specific1">申请人从事灵活性就业，并在青岛行政区域内以灵活就业人员身份缴纳城镇职工社会保险费的，可申领每人每月最高500元的社会保险补贴，补贴期限为3年。</div>
  </div>
</template>

<script>
import "@/sass/views/Jumpdetails/detaila.scss";
export default {

}
</script>

<style>

</style>